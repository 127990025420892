.wrapper {
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 5;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background: transparent;
  transition: 0.3s;
  padding: 0px 0;

  background: var(--Bg-White);
  border-bottom: 1px solid transparent;
}
.wrapperBg {
  border-bottom: 1px solid var(--Border-Light);
}
.logo {
  max-width: 150px;
  width: 100%;
}

.header {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 40px;
  padding: 16px 0;
  width: 90%;
  max-width: 1280px;
  margin: 0 auto;
}
.logoContainer {
  /* min-width: 123.63px; */
  display: flex;
  align-items: center;
  gap: 12px;
}

.navItems {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 28px;
}
.navItem {
  color: var(--Text-Teritary);
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}
.navActive {
  color: var(--Heading-Color);
  font-weight: 500;
}

.buttonContainer {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-left: auto;
}
.buttons {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-left: auto;
}
.button {
  border-radius: var(--radius);
}
.login {
  border: 1px solid #f1f5f9;
  background: var(--Bg-White);
  color: var(--Text-Primary);
}
.buttonContainer .button {
  gap: 5px;
  margin-left: auto;
}

.arrow {
  font-size: 18px;
}
.icon {
  display: none;
}
.mobileButton {
  display: none;
}

@media only screen and (max-width: 991px) {
  .wrapper {
    padding: 10px 0;
  }
  .logoContainer {
    padding: 0;
    border: none;
  }
  .buttons {
    margin: 0;
  }
  .header {
    width: calc(100% - 48px);
  }
  .navItems {
    flex-direction: column;
    align-items: flex-start;
    gap: 18px;
    padding: 0 24px;

    min-height: 100vh;
    background: var(--Bg-White);
    transform: translateX(250%);
    transition: 0.3s;
    position: fixed;
    top: calc(100% + 1px);
    left: auto;
    right: 0;
    padding-top: 30px;

    width: calc(100%);
    max-width: 280px;

    min-height: 100vh;
    border: none;
    backdrop-filter: blur(0px);
    border-left: 1px solid var(--Border-Light);
    border-radius: 0;
  }
  .dropdownRef {
    width: 100%;
  }
  .sidebar {
    transform: translateX(0%);
    right: 0;
  }

  .icon {
    display: block;
    cursor: pointer;
    color: var(--Base-700);
    font-size: 24px;
  }
  .hamburger {
    color: var(--Base-700);
  }
}
@media only screen and (max-width: 520px) {
  .header {
    width: calc(100% - 40px);
  }
  .navItems {
    padding-left: 20px;
    padding-right: 20px;
  }
  .logo {
    max-width: 100px;
  }
  .buttonContainer .button {
    padding: 8px 12px;
  }
}
@media only screen and (max-width: 450px) {
  .waitingList {
    display: none;
  }
}
