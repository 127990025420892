.wrapper {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.header {
  display: flex;
  flex-direction: column;
  gap: 5px;
  max-width: 512px;
  width: 100%;
}

.heading {
  padding: 12px 0;
  padding-top: 0;
  border-bottom: 1px solid var(--Border-Light);
}
.items,
.inputWrapper {
  display: grid;
  grid-template-columns: 1fr 2fr;
  align-items: center;
  gap: 15px;
  padding: 10px 0;
  border-bottom: 1px solid var(--Border-Light);
  height: 60px;
}

.copyContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
}
.copyIcon {
  color: var(--Text-Teritary);
  font-size: 13px;
  cursor: pointer;
}
.input {
  padding: 0;
  border: none;
  padding: 10px 0;
  background: transparent;
}

.inputContainer,
.ipContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
}

.editIcon {
  font-size: 24px;
  color: var(--Text-Placeholder);
  cursor: pointer;
}
