.wrapper {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.button {
  gap: 5px;
  margin-right: auto;
  width: max-content;
  padding: 0;
}
.container {
  display: grid;
  grid-template-columns: 220px 1fr;
  gap: 20px;
}
@media only screen and (max-width: 1199px) {
  .container {
    display: flex;
    flex-direction: column;
  }
}
