.chart {
  width: calc(100% + 20px);
  min-height: 250px;
  margin-top: -20px;
}
.customXaxisTooltip {
  background: var(--Base-White) !important;
  border: 1px solid #ccc;
  padding: 8px;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  color: #333;
  font-family: Inter, sans-serif;
  font-size: 12px;
  max-width: 100px !important;
  text-align: center !important;
  left: 50%;
}
