.header {
  display: flex;

  align-items: center;
  gap: 12px;
  width: 100%;
  padding: 20px 0;
  border-bottom: 1px solid var(--Border-Light);
}

.closeIcon {
  font-size: 18px;
  color: var(--Text-Placeholder);
  cursor: pointer;
  margin-left: auto;
}
.hamburger {
  font-size: 20px;
  color: var(--Primary-Color);

  display: none;
}
@media only screen and (max-width: 991px) {
  .hamburger {
    display: block;
    cursor: pointer;
  }
}
