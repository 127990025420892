.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 32px;
  max-width: 300px;
  width: 100%;
  margin: 0 auto;
}
.img {
  max-width: 96px;
  width: 100%;
}
.header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
}
