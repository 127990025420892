@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&display=swap");

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: "Inter";
}
:root {
  --Primary-Default: #336fe1;
  --Success-Default: #4edba4;
  --Heading-Color: #020617;
  --Text-Primary: #334155;
  --Text-Secondary: #475569;
  --Text-Placeholder: #9aa9be;
  --Text-Teritary: #64748b;
  --Base-White: #fff;
  --Bg-White: #fff;
  --Primitive: #f3f7fd;
  --Bg-Light: #f8fafc;
  --Border-Light: #e2e8f0;
  --radius-sm: 4px;
  --radius: 6px;
  --radius-xl: 10px;
  --Error: #f24141;
  --Warning: #ffb729;
  --Success: #4edba4;
  --Info: #f27b4a;
}

body {
  padding: 0;
  min-height: 100vh;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
}
a {
  text-decoration: none;
  color: inherit;
  text-decoration: none;
}
input {
  border: none;
  outline: none;
  background: transparent;
}
button,
a {
  border: none;
  outline: none;
  cursor: pointer;
  transition: 0.3s;
  background: transparent;
}
button:hover,
a:hover {
  opacity: 0.8;
  transform: translateY(-3px);
}
.container {
  max-width: 1280px;
  width: 90%;
  margin: 0 auto;
}
.highlight {
  color: var(--Text-Teritary);
}
::-webkit-scrollbar {
  width: 3px;
  background: rgba(250, 250, 250, 0.2);
}

::-webkit-scrollbar-thumb {
  background-color: var(--Base-100);
  border-radius: 4px;
}
.overflow {
  scrollbar-width: thin;
  scrollbar-color: var(--Primary-Default) rgba(250, 250, 250, 0.2);
  border-radius: 5px;
}

/* Chrome, Edge, and Safari browsers */
.overflow::-webkit-scrollbar {
  width: 3px;
  border-radius: 5px;
}
.overflow::-webkit-scrollbar-track {
  background: rgba(250, 250, 250, 0.2);
  border-radius: 5px;
}

.overflow::-webkit-scrollbar-thumb {
  background: var(--Primary-Default);
  border-radius: 100vh;
  border: none;
  border-radius: 5px;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
