.billSummary {
  display: grid;
  grid-template-columns: 1.7fr 1.3fr 1fr 1fr;
  align-items: center;
  width: 100%;
}
.item {
  padding: 12px 20px;
  white-space: nowrap;
  border-bottom: 1px solid var(--Border-Light);
}

.serverName,
.date {
}

.amount {
  text-align: right;
}

@media only screen and (max-width: 767px) {
}
@media only screen and (max-width: 520px) {
}
