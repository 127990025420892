.wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.info {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 8px;
}

.box,
.freeTrailBox,
.resource,
.whatsNew {
  border-radius: var(--radius-xl);
  border: 1px solid var(--Border-Light);
  background: var(--Base-White);
  padding: 0 16px;
}
.heading {
  padding: 16px 0;
  border-bottom: 1px solid var(--Border-Light);
}
.button {
  padding: 16px 0;
  padding-left: 0;
  padding-right: 0;
}
.icon {
  font-size: 18px;
}
.freeTrialHeading {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.freeTrailBox {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
}
.amountContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 20px 0;
  border-top: 1px solid var(--Border-Light);
  border-bottom: 1px solid var(--Border-Light);
}
.perHour,
.perMonth {
  display: flex;
  flex-direction: column;
  gap: 2px;
}
.perHour {
  border-right: 1px solid var(--Border-Light);
  padding-right: 12px;
}
.perMonth {
  padding-left: 12px;
}
.resource,
.whatsNew {
  padding: 20px;
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 12px;
  align-items: end;
  transition: 0.3s;
}
.resource:hover,
.whatsNew:hover {
  transform: translateY(-3px);
}
.resourceHeading,
.whatsNewHeading {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.resource .arrow {
  cursor: pointer;
  color: var(--Text-Placeholder);
  margin-left: auto;
  margin-bottom: 1px;
}
.whatsNew {
  background: var(--Primary-Default);
}
.whatsNew .arrow {
  cursor: pointer;
  color: var(--Base-White);
  margin-left: auto;
  margin-bottom: 1px;
}
.arrow {
  transition: 0.3s;
}
.arrow:hover {
  transform: translateY(-3px);
}
@media only screen and (max-width: 520px) {
  .info {
    grid-template-columns: 1fr;
  }
  .heading {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .button {
    width: 100%;
  }
  .amountContainer {
    grid-template-columns: 1fr;
  }
  .perHour,
  .perMonth {
    padding: 20px 0;
  }
  .perHour {
    border-left: none;
    border-bottom: 1px solid var(--Border-Light);
    padding-top: 0;
  }
  .perMonth {
    padding-bottom: 0;
  }
}
