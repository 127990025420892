/* src/CircularProgressBar.module.css */
.progressbar {
  transform: rotate(270deg);
}

.background {
  transition: stroke-dashoffset 0.35s;

  transform-origin: 50% 50%;
}

.foreground {
  transition: stroke-dashoffset 0.35s;

  transform-origin: 50% 50%;
}
.progressbar {
  display: block;
  margin: 10px auto;
}

.background {
  transition: stroke-dashoffset 0.35s;

  transform-origin: 50% 50%;
}

.foreground {
  transition: stroke-dashoffset 0.35s;

  transform-origin: 50% 50%;
}

.text {
  font-size: 14px;
  font-family: Arial, sans-serif;
  font-weight: bold;
  transform: rotate(90deg);
  transform-origin: center;
  font-weight: 600;
}
