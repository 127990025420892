.singleServerDetails {
  border-bottom: 1px solid var(--Border-Light);
  background: var(--Bg-White);

  display: grid;
  grid-template-columns: 1fr auto auto auto auto;
  align-items: center;
  padding-bottom: 16px;
}
.logoNameAndCreatedAt {
  display: grid;
  grid-template-columns: auto 1fr;
}
.logoContainer {
  border-radius: var(--radius-sm);
  background: var(--Bg-Light);
  width: 64px;
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.logo {
  max-width: 40px;
  width: 100%;
}

.nameAndcreatedAt {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px 18px;
}
.nameAndcreatedAt,
.ipAddressContainer,
.specificationContainer {
  padding: 0 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  height: 100%;
  gap: 5px;
}

.specificationContainer,
.ipAddressContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-left: 1px solid var(--Border-Light);
}
.createdAtAndIP p {
  white-space: nowrap;
}
.nameAndstatus {
  display: flex;
  align-items: center;
  gap: 6px;
}
.ip {
  display: flex;
  align-items: center;
  gap: 6px;
}

.copyIpIcon {
  color: var(--Text-Teritary);
  font-size: 13px;
  cursor: pointer;
}
.specification {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 3px;
}
.box {
  padding: 2px 6px;
  border-radius: var(--radius-sm);
  background: var(--Bg-Light);
  border: 1px solid var(--Border-Light);
  text-transform: capitalize;
}
.off {
  color: var(--Error);
  background: #fdf4f4;
  border: 1px solid #fce3e3;
}
.on {
  color: var(--Success);
  background: #e2faf0;
  border: 1px solid #f2fdf9;
}
.restarting {
  color: var(--Warning);
  background: #fff4de;
  border: 1px solid #fff4de;
}
.applicationAndProjects {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 4px;
  border-left: 1px solid var(--Border-Light);
  border-right: 1px solid var(--Border-Light);
  height: 100%;
}
.applications,
.projects {
  border-radius: 60px;
  border: 1px solid var(--Border-Light);
  background: var(--Bg-White);
  padding: 2px 8px;
  padding-right: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
}

.numbeOfApplications,
.numberOfProjects {
  border-radius: 50%;
  background: var(--Primitive);
  padding: 2px 6px;
  min-width: 18px;
  min-height: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.infoContainer,
.applicationAndProjects,
.moreWrapper {
  padding: 0px 16px;
}
.moreWrapper {
  position: relative;
}
.moreContainer {
  width: 32px;
  height: 32px;

  border-radius: var(--radius);
  border: 1px solid var(--Border-Light);
  background: var(--Bg-Light);
  display: flex;
  justify-content: center;
  align-items: center;
}

/* dropdown */
.button {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 8px 10px;
  color: var(--Text-Teritary);
  width: 100%;
}
.button:hover {
  transform: translateY(0);
  opacity: 1;
  background: var(--Bg-Light);
  color: var(--Text-Primary);
}
.button:hover .icon,
.button:hover .item {
  color: var(--Text-Primary);
}
.icon {
  font-size: 18px;
  color: var(--Text-Teritary);
}
.addTransferClone {
  padding: 5px 0;
  border-top: 1px solid var(--Border-Light);
  border-bottom: 1px solid var(--Border-Light);
  display: flex;
  flex-direction: column;
  gap: 2px;
}

@media only screen and (max-width: 1199px) {
  .singleServerDetails {
    grid-template-columns: 1fr auto auto;
    grid-template-rows: auto auto;
    grid-template-areas:
      "logoNameAndCreatedAt logoNameAndCreatedAt moreWrapper"
      "specificationContainer ipAddressContainer applicationAndProjects";
    gap: 12px 0;
  }

  .logoNameAndCreatedAt {
    grid-area: logoNameAndCreatedAt;
  }

  .ipAddressContainer {
    grid-area: ipAddressContainer;
  }

  .specificationContainer {
    grid-area: specificationContainer;
  }

  .applicationAndProjects {
    grid-area: applicationAndProjects;
  }

  .moreWrapper {
    grid-area: moreWrapper;
    display: flex;
    justify-content: flex-end;
    padding-right: 0;
  }
  .specificationContainer {
    padding-left: 0;
    border-left: none;
  }
}

@media only screen and (max-width: 767px) {
  .singleServerDetails {
    display: grid;
    grid-template-columns: 1fr auto;
    grid-template-rows: auto auto auto;
    grid-template-areas:
      "logoNameAndCreatedAt moreWrapper"
      "specificationContainer ipAddressContainer"
      "applicationAndProjects applicationAndProjects";
  }
  .applicationAndProjects {
    padding-left: 0;
    padding-right: 0;
    border: none;
    display: flex;
    align-items: flex-start;
  }
}
@media only screen and (max-width: 520px) {
  .singleServerDetails {
    display: grid;
    grid-template-columns: 1fr auto;
    grid-template-rows: auto auto auto auto;
    grid-template-areas:
      "logoNameAndCreatedAt moreWrapper"
      "specificationContainer specificationContainer"
      "ipAddressContainer ipAddressContainer"
      "applicationAndProjects applicationAndProjects";
    gap: 15px;
  }
  .ipAddressContainer {
    padding-left: 0;
    padding-right: 0;
    border: none;
    display: flex;
    align-items: flex-start;
    border-bottom: 1px solid var(--Border-Light);
    padding-bottom: 15px;
  }
  .specificationContainer {
    border-bottom: 1px solid var(--Border-Light);
    padding-bottom: 15px;
  }
}
