.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 40px;

  border-radius: 16px;
  border: 1px solid #fdeae2;
  background: linear-gradient(
    to right,
    rgba(253, 234, 226, 0.3) 0%,
    rgba(226, 235, 251, 0.3) 100%
  );
  max-width: 846px;
  margin: 0 auto;
  padding: 48px;
}
.heading {
  line-height: 106%;
}
.infoContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: flex-start;
  max-width: 360px;
  width: 100%;
}

.buttonContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 8px;
  padding-top: 16px;
}
.steps {
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-width: max-content;
}

.step {
  display: flex;
  align-items: start;
  gap: 8px;
}
.checkmarkIcon {
  max-width: 12px;
  margin-top: 6px;
}
@media only screen and (max-width: 991px) {
  .container {
    display: flex;
    flex-direction: column;
    gap: 32px;
    justify-content: center;
    align-items: flex-start;
    padding: 36px;
  }

  .infoContainer {
    max-width: 100%;
    margin-left: auto;
  }
}
@media only screen and (max-width: 767px) {
  .heading {
    font-size: 20px;
  }
  .info {
    font-size: 14px;
  }
}
@media only screen and (max-width: 640px) {
}
@media only screen and (max-width: 520px) {
  .container {
    padding: 32px;
  }
  .heading {
    font-size: 18px;
  }
  .infoContainer {
    gap: 8px;
  }
  .buttonContainer {
    padding-top: 8px;
  }
}
