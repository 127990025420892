.wrapper {
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-height: 755px;
  height: 100%;
  overflow-y: auto;
}
@media only screen and (max-width: 991px) {
  .wrapper {
    padding: 16px;
  }
}
