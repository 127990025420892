.wrapper {
  display: flex;
  flex-direction: column;
  gap: 32px;

  width: 100%;
}
.header {
  display: flex;
  flex-direction: column;
  gap: 5px;
  max-width: 512px;
  width: 100%;
}
.paymentContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.switchContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  max-width: 500px;
  width: 100%;
}
.paymentMethodContainer {
  width: 100%;
}
.paymentMethodHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 15px;
  border-bottom: 1px solid var(--Border-Light);
  padding: 10px 0;
}

.button {
  padding: 0;
  display: flex;
  align-items: center;
  gap: 5px;
  margin-left: auto;
}
.addIcon {
  font-size: 18px;
}
.methods {
  display: grid;
  grid-template-columns: 1fr auto auto auto;
  width: 100%;
  overflow-x: auto;
  padding-bottom: 10px;
}
.logoAndName,
.item,
.deleteContainer {
  padding: 8px 16px;
  border-bottom: 1px solid var(--Border-Light);
  min-height: 55px;
  display: flex;
  align-items: center;
}
.logoAndName {
  padding-left: 0;
  display: grid;
  grid-template-columns: 40px 1fr;
  gap: 12px;
}
.deleteContainer {
  padding-right: 0;
  display: flex;
  justify-content: flex-end;
}
.cardLogo {
  max-width: 34px;
}
.deleteIcon {
  cursor: pointer;
  color: var(--Text-Placeholder);
}
