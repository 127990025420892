.modal {
  align-items: start;
  min-height: auto;
  max-width: 800px;
  width: 95%;
  border-radius: 16px;
}

.header {
  display: flex;

  align-items: center;
  gap: 12px;
  width: 100%;
  padding: 14px 20px;
  border-bottom: 1px solid var(--Border-Light);
}

.closeIcon {
  font-size: 18px;
  color: var(--Text-Placeholder);
  cursor: pointer;
  margin-left: auto;
}
.container {
  display: grid;
  grid-template-columns: 1fr 270px;
  gap: 48px;
  align-items: start;
  padding: 32px;
}
/* ticket info */

.ticketInfoContainer {
  border-radius: 10px;
  border: 1px solid var(--Border-Light);
  background: var(--Base-White);
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.heading {
  padding-bottom: 20px;
  border-bottom: 1px solid var(--Border-Light);
}
.info {
  padding: 20px 0;
  border-bottom: 1px solid var(--Border-Light);
  border-top: 1px solid var(--Border-Light);
}
.spaceBetween {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
}
.status,
.priority {
  padding: 2px 4px;
  border-radius: var(--radius-sm);
  background: var(--Bg-Light);
  border: 1px solid var(--Border-Light);
  text-transform: capitalize;
  text-align: center;
  font-size: 12px;
}
.opened {
  color: var(--Primary-Default);
  background: #f3f7fd;
  border: 1px solid #e2ebfb;
}
.solved,
.low {
  color: var(--Success);
  background: #e2faf0;
  border: 1px solid #f2fdf9;
}
.pending,
.medium {
  color: var(--Warning);
  background: #fff4de;
  border: 1px solid #fff4de;
}
.high {
  color: var(--Error);
  background: #fdf4f4;
  border: 1px solid #fce3e3;
}
@media only screen and (max-width: 991px) {
  .modal {
    max-width: 500px;
  }
  .container {
    grid-template-columns: 1fr;
    padding: 20px 16px;
  }
}
@media only screen and (max-width: 380px) {
  .ticketInfoContainer {
    padding: 12px;
  }
}
