.wrapper {
  display: flex;
  flex-direction: column;
  gap: 64px;
}

.header {
  max-width: 718px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.container {
  columns: 3;
  column-gap: 15px;
}
.feature {
  background: linear-gradient(
    90deg,
    rgba(253, 234, 226, 0.3) 0%,
    rgba(226, 235, 251, 0.3) 100%
  );
  border-radius: 16px;
  gap: 80px;
  border: 1px solid #fdeae2;
  break-inside: avoid;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  margin-top: 15px;
}
.feature:first-child {
  margin: 0;
}
.icon {
  max-width: 32px;
}
@media only screen and (max-width: 1199px) {
  .container {
    columns: 2;
  }
  .feature {
    padding: 16px;
  }
}
@media only screen and (max-width: 991px) {
}
@media only screen and (max-width: 767px) {
  .wrapper {
    gap: 32px;
  }

  .title {
    font-size: 20px;
  }
  .info {
    font-size: 14px;
  }
}
.tagline {
  font-size: 18px;
}

@media only screen and (max-width: 640px) {
  .container {
    columns: 1;
  }
}
@media only screen and (max-width: 520px) {
  .tagline {
    font-size: 16px;
  }
}
