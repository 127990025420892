.sidebar {
  display: flex;
  flex-direction: column;
  gap: 12px;
  max-width: 252px;
  width: 100%;
  padding: 18px 12px;

  left: 0px;
  top: 0px;
  background: var(--Bg-Light);
  border-right: 1px solid var(--Border-Light);
  z-index: 5;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 4;
}
.header {
  border-bottom: 1px solid var(--Border-Light);
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
}

.navItems {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 16px 12px;
  height: 100%;
}

.navItem {
  color: var(--Text-Teritary);
  font-size: 14px;
  font-weight: 600;
  line-height: 142.87%;
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 8px;
  white-space: nowrap;
}

.navItem:last-child {
  margin-top: auto;
}
.navActive {
  font-weight: 600;
  color: var(--Primary-Default);
}
.navIcon {
  max-width: 18px;
  width: 100%;
}

.closeIcon {
  font-size: 18px;
  cursor: pointer;
  color: var(--Text-Placeholder);
  display: none;
}

/* ===== Scrollbar CSS ===== */
/* Firefox */

.sidebar {
  scrollbar-width: thin;
  scrollbar-color: var(--Primary-Default) var(--Bg-Light);
}
/* For Firefox */
/* For Firefox */

/* Chrome, Edge, and Safari */
.sidebar::-webkit-scrollbar {
  width: 3px;
}

.sidebar::-webkit-scrollbar-track {
  background: var(--Bg-Light);
}

.sidebar::-webkit-scrollbar-thumb {
  background: var(--Primary-Default);
  border-radius: 100%;
  border: none;
}
@media only screen and (max-width: 991px) {
  .sidebar {
    transform: translateX(-150%);
    transition: 0.3s;
    left: 0;
    position: fixed;
    top: 0;
    z-index: 5;
  }
  .overlay {
    position: fixed;

    background: rgba(0, 0, 0, 0.4);
    filter: blur(5px);
    height: 100vh;
    width: 100%;
    inset: 0;
    z-index: 1;
    cursor: pointer;
  }
  .showSidebar {
    transform: translateX(0%);

    transition: 0.3s;
  }
  .closeIcon {
    display: block;
  }
}
