.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  max-width: 280px;

  margin: auto;
}

.inutContainer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 8px;
  width: 100%;
}
.bold {
  color: var(--Heading-Color);
  font-weight: 600;
}
.input {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2px;
  padding: 12px 0px;

  width: 100%;
  border: 1px solid var(--Border-Light);
  color: var(--Text-Placeholder);
  min-width: 32px !important;
  min-height: 32px !important;
  border-radius: 6px;
  width: 100% !important;
  font-size: 18px;
  margin-right: 5px !important;
}
.helperError {
  color: var(--Warning);
}
.resendButton {
  border-radius: 4px;
  border: 1px solid var(--Border-Light);
  background: var(--Bg-Light);
  padding: 2px 4px;
  margin: 0 auto;
}
