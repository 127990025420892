.wrapper {
  display: flex;
  flex-direction: column;
  gap: 32px;
  max-width: 598px;
  width: 100%;
  margin: 0 auto;
}

.header {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}
.titleAndInfo {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.logo {
  max-width: 64px;
  width: 100%;
}
.container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  gap: 64px;
  position: relative;
}
.container::before {
  content: "";
  width: 1px;
  height: calc(100%);
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--Border-Light);
}
.or {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 12px;
  background: var(--Bg-White);
}
.inputContainer,
.allSocialLinks {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.socialContainer {
  display: flex;
  justify-content: space-between;
  gap: 16px;
  background: var(--Bg-Light);
  padding: 12px 16px;
  border-radius: var(--radius);
}
.socialLogo {
  max-width: 20px;
  width: 100%;
}
.buttonContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
}

.ask {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
}
.signUp {
  color: var(--Primary-Default);
  font-weight: 600;
}
@media only screen and (max-width: 640px) {
  .container {
    display: flex;
    flex-direction: column-reverse;
    max-width: 400px;
    width: 100%;
    margin: 0 auto;
  }

  .container::before {
    height: 1px;
    width: 100%;
  }
}
