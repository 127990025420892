.wrapper {
  display: flex;
  flex-direction: column;
  gap: 32px;
  max-width: 598px;
  width: 100%;
  margin: 0 auto;
}

.header {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}
.titleAndInfo {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.logo {
  max-width: 64px;
  width: 100%;
}
.container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  gap: 32px;
}
.youWillGetFree {
  padding: 18px;
  background: var(--Bg-Light);
  border-radius: var(--radius-xl);
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  height: 100%;
}
.freeItems {
  display: flex;
  flex-direction: column;
  gap: 6px;
  padding-bottom: 18px;
}
.freeItem {
  display: flex;
  align-items: flex-start;
  gap: 8px;
}

.checkMark {
  max-width: 12px;
  width: 100%;
  margin-top: 3px;
}
.info {
  margin-top: auto;
  padding-top: 18px;
  border-top: 1px solid var(--Border-Light);
}
.rightSide {
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: 100%;
  width: 100%;
}
.allSocialLinks {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 8px;
}
.socialContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--Bg-Light);
  border-radius: var(--radius);
  width: 44px;
  height: 44px;
}
.socialLogo {
  max-width: 20px;
  width: 100%;
}

.or {
  position: relative;

  padding: 0 12px;
  background: var(--Bg-White);
  width: 100%;
}
.or::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  width: 43%;
  height: 1px;
  transform: translateY(-50%);
  background-color: var(--Border-Light);
}
.or::after {
  content: "";
  position: absolute;
  right: 0;
  top: 50%;
  width: 43%;
  height: 1px;
  transform: translateY(-50%);
  background-color: var(--Border-Light);
}
.inputContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.inputWrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 8px;
}
.ask {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
}
.signUp {
  color: var(--Primary-Default);
  font-weight: 600;
}
.highlight {
  color: var(--Primary-Default);
  font-weight: 500;
}
@media only screen and (max-width: 640px) {
  .container {
    display: flex;
    flex-direction: column-reverse;
  }
}
