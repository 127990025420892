.wrapper {
  max-width: 740px;
  width: 90%;
  margin: 0 auto;
  margin: 120px auto;
  margin-bottom: 50px;
  display: flex;
  flex-direction: column;
  gap: 40px;
}
.heading {
  line-height: 111%;
}

.topPart {
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 40px;
}
.infoContainer {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.howItStart {
  background: linear-gradient(
    90deg,
    rgba(253, 234, 226, 0.7) 0%,
    rgba(226, 235, 251, 0.7) 100%
  );
  border-radius: 16px;
  gap: 80px;
  border: 1px solid #fdeae2;
  break-inside: avoid;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  max-width: 280px;
  width: 100%;
}
.steps {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.step {
  display: flex;
  align-items: start;
  gap: 8px;
}
.checkmarkIcon {
  max-width: 12px;
  margin-top: 6px;
}
.button {
  margin-top: auto;
}

.affiliateInfoContainer {
  padding-left: 40px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 15px;
}
.affiliateInfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding: 20px;
  border-radius: var(--radius-xl);
}
.number {
  display: flex;
  align-items: center;
}
.plusIcon {
  font-size: 32px;
}
.facilitiesContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 8px;
}
.facility {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 16px;
  border-radius: 8px;
  border: 1px solid var(--Border-Light);
}
@media only screen and (max-width: 1199px) {
  .wrapper {
    margin: 100px auto;
    margin-bottom: 50px;
  }
  .affiliateInfoContainer {
    padding: 0;
  }
}
@media only screen and (max-width: 991px) {
  .wrapper {
    gap: 36px;
  }
  .topPart {
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
  }
  .topPart {
    grid-template-columns: 1fr;
  }
}

@media only screen and (max-width: 767px) {
  .affiliateInfoContainer {
    grid-template-columns: 1fr 1fr;
    gap: 16px;
  }
  .affiliateInfo {
    border: 1px solid var(--Border-Light);
  }
  .affiliateInfo:last-child {
    grid-column: 1/-1;
  }
}
@media only screen and (max-width: 640px) {
  .facilitiesContainer {
    grid-template-columns: 1fr;
  }
}
@media only screen and (max-width: 520px) {
  .affiliateInfoContainer {
    grid-template-columns: 1fr;
  }
  .howItStart {
    max-width: 100%;
  }
  .facility {
    padding: 12px;
  }
}
