.button {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 133.333%;
  letter-spacing: -0.2px;
  display: flex;
  justify-content: center;
  gap: 8px;
  align-items: center;

  padding: 10px 16px;

  background: var(--Primary-Default);
  color: var(--Base-White);
  cursor: pointer;
  white-space: nowrap;
  border-radius: var(--radius);
}

.btnPrimary {
  background: var(--Primary-Default);
  color: var(--Base-White);
  border: 1px solid var(--Primary-Default);
}
.btnInfo {
  background: var(--Info);
  color: var(--Base-White);
}
.btnSuccess {
  background: var(--Success-Default);
  color: var(--Base-White);
}
.transparent {
  background: transparent;
  color: var(--Primary-Default);
}
.infoTransparent {
  background: transparent;
  color: var(--Info);
}

.wFull {
  width: 100%;
}
.spinner {
  display: inline-block;
  animation: spin 1s linear infinite;
  margin-right: 5px; /* Add some space between the spinner and the text */
}
.loading {
  gap: 0px;
  opacity: 0.8;
  cursor: not-allowed;
}
.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
.disabled:hover {
  opacity: 0.5;
  transform: translateY(0);
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
