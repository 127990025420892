.wrapper {
  display: flex;
  flex-direction: column;
  gap: 32px;
}
.header {
  display: flex;
  align-items: center;
  gap: 15px;
  flex-wrap: wrap;
}

.button {
  margin-left: auto;
  gap: 10px;
}

.titleAndInfo {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.numberOfTickets {
  color: var(--Text-Primary);
  font-weight: 600;
}
.container {
  display: flex;
  flex-direction: column;
  gap: 2px;
  border: 1px solid var(--Border-Light);
  border-radius: var(--radius);
  padding: 12px;
  padding-right: 0;
  padding-top: 0;
}

.allTickets {
  display: grid;
  grid-template-columns: auto 1fr auto auto auto;
  align-items: center;
  overflow-x: auto;
  padding-top: 0;
  /* max-height: calc(100vh - 120px); */
  background: var(--Bg-White);
  position: relative;
  padding-right: 10px;
}

.tableHeading {
  padding: 12px 15px;
  background: var(--Bg-White);
  white-space: nowrap;
  position: sticky;
  position: -webkit-sticky;
  top: 0px;
  padding-top: 20px;
  height: 100%;
}
.item {
  padding: 12px 15px;
  white-space: nowrap;
  border-top: 1px solid var(--Border-Light);
  height: 100%;
  cursor: pointer;
}

.view {
  text-align: right;
  color: var(--Primary-Default);
  padding-right: 0;
  font-weight: 500;
  font-size: 14px;
  border-radius: 0;
}
.view:hover {
  transform: translateY(0);
}
.status {
  padding: 2px 4px;
  border-radius: var(--radius-sm);
  background: var(--Bg-Light);
  border: 1px solid var(--Border-Light);
  text-transform: capitalize;
  text-align: center;
  font-size: 12px;
}
.opened {
  color: var(--Primary-Default);
  background: #f3f7fd;
  border: 1px solid #e2ebfb;
}
.solved {
  color: var(--Success);
  background: #e2faf0;
  border: 1px solid #f2fdf9;
}
.pending {
  color: var(--Warning);
  background: #fff4de;
  border: 1px solid #fff4de;
}
