.wrapper {
  display: flex;
  flex-direction: column;
  gap: 64px;
  max-width: 718px;
  width: 100%;
  margin: 0 auto;
}
.header {
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 488px;
}
.questionWrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.icon {
  color: var(--Text-Teritary);
  font-size: 20px;
  cursor: pointer;
  margin-left: 15px;
  margin-top: 3px;
}

.faqContainer {
  border-bottom: 1px solid var(--Border-Light);
  border-radius: var(--radius);
  margin: 18px 0;
  padding: 15px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  cursor: pointer;
}

.faqContainer:last-child {
  border-bottom: none;
}
.activeFaqContainer {
  background: var(--Bg-Light);
  border: 1px solid var(--Border-Light);
}
@media only screen and (max-width: 1199px) {
}
@media only screen and (max-width: 767px) {
  .wrapper {
    gap: 32px;
  }
  .question {
    font-size: 18px;
  }
  .answer {
    font-size: 16px;
  }
}
@media only screen and (max-width: 520px) {
  .question {
    font-size: 16px;
  }
  .answer {
    font-size: 14px;
  }
  .icon {
    font-size: 16px;
    cursor: pointer;
  }
}
