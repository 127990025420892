.singleServer {
  border-radius: 8px;
  border: 1px solid var(--Border-Light);
  background: var(--Bg-White);
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  padding: 2px;
}
.otherInfo {
  display: grid;
  grid-template-columns: 1fr auto auto;
  align-items: center;
  padding: 16px 0;
}

.logoContainer {
  border-radius: var(--radius-sm);
  background: var(--Bg-Light);
  width: 94px;
  height: 94px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100%;
}
.logo {
  max-width: 40px;
  width: 100%;
}
.infoContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.createdAtAndIP {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px 18px;
}
.createdAtAndIP p {
  white-space: nowrap;
}
.domainIp {
  display: flex;
  align-items: center;
  gap: 6px;
}

.copyIpIcon {
  color: var(--Text-Teritary);
  font-size: 13px;
  cursor: pointer;
}
.specification {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 3px;
}
.box {
  padding: 2px 6px;
  border-radius: var(--radius-sm);
  background: var(--Bg-Light);
  text-transform: capitalize;
}
.off {
  color: var(--Error);
  background: #fdf4f4;
  border: 1px solid #fce3e3;
}
.on {
  color: var(--Success);
  background: #e2faf0;
  border: 1px solid #f2fdf9;
}
.restarting {
  color: var(--Warning);
  background: #fff4de;
  border: 1px solid #fff4de;
}
.applicationAndProjects {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 4px;
  border-left: 1px solid var(--Border-Light);
  border-right: 1px solid var(--Border-Light);
  height: 100%;
}
.applications,
.projects {
  border-radius: 60px;
  border: 1px solid var(--Border-Light);
  background: var(--Bg-White);
  padding: 2px 8px;
  padding-right: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
}

.numbeOfApplications,
.numberOfProjects {
  border-radius: 50%;
  background: var(--Primitive);
  padding: 2px 6px;
  min-width: 18px;
  min-height: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.infoContainer,
.applicationAndProjects,
.moreWrapper {
  padding: 0px 16px;
}
.moreWrapper {
  position: relative;
}
.moreContainer {
  width: 32px;
  height: 32px;

  border-radius: var(--radius);
  border: 1px solid var(--Border-Light);
  background: var(--Bg-Light);
  display: flex;
  justify-content: center;
  align-items: center;
}

/* dropdown */
.button {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 8px 10px;
  color: var(--Text-Teritary);
  width: 100%;
}
.button:hover {
  transform: translateY(0);
  opacity: 1;
  background: var(--Bg-Light);
  color: var(--Text-Primary);
}
.button:hover .icon,
.button:hover .item {
  color: var(--Text-Primary);
}
.icon {
  font-size: 18px;
  color: var(--Text-Teritary);
}
.addTransferClone {
  padding: 5px 0;
  border-top: 1px solid var(--Border-Light);
  border-bottom: 1px solid var(--Border-Light);
  display: flex;
  flex-direction: column;
  gap: 2px;
}

@media only screen and (max-width: 767px) {
  .singleServer {
    grid-template-columns: 1fr;
  }
  .logoContainer {
    width: 100%;
  }
  .otherInfo {
    grid-template-columns: 1fr auto;
    gap: 15px 0;
    align-items: flex-start;
  }
  .applicationAndProjects {
    border: none;
    order: 3;
    grid-template-columns: 1/-1;
    align-items: flex-start;
  }
  .moreContainer {
    order: 2;
  }
  .infoContainer,
  .applicationAndProjects,
  .moreWrapper {
    padding: 0px 12px;
  }

  .createdAtAndIP {
    flex-wrap: wrap;
    gap: 8px 16px;
  }
  .specification {
    flex-wrap: wrap;
  }
}
