.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  padding-left: 10px;
  border: 1px solid var(--Border-Light);
  border-radius: var(--radius);
}
.header {
  display: flex;

  align-items: center;
  justify-content: space-between;
  gap: 15px;

  width: 100%;
}

.dropdownContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-start;
  min-width: 180px;
}
.info {
  max-width: 420px;
  width: 100%;
}
@media only screen and (max-width: 767px) {
  .header {
    flex-direction: column-reverse;
  }
  .info {
    max-width: 100%;
  }
  .dropdownContainer {
    flex-direction: row;
    width: 100%;
  }
}
@media only screen and (max-width: 520px) {
  .dropdownContainer {
    flex-direction: column;
    width: 100%;
  }
}
