.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 32px;
  padding-bottom: 64px;
}

.img {
  border-radius: 16px;

  max-width: 846px;
  margin: 0 auto;
  width: 100%;
}
