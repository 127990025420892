.usagesInfoContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
}
.usageContainer,
.bandwithInfo {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  gap: 8px;
  width: 100%;
  z-index: -1;
}

.usageBox {
  border-radius: var(--radius-xl);
  border: 1px solid var(--Border-Light);

  padding: 0 12px;
  display: grid;
  align-items: center;

  grid-template-columns: 1fr auto;
  gap: 18px;
  height: 100%;
}
.infoBox {
  display: flex;
  flex-direction: column;
  gap: 2px;
  padding: 10px 0;
}
.bandwithInfo {
  border-radius: var(--radius-xl);
  border: 1px solid var(--Border-Light);
}
.bandwithInfo .usageBox {
  border: none;

  position: relative;
}
.bandwithInfo .usageBox::after {
  content: "";
  position: absolute;
  right: 0;
  width: 1px;
  height: 60%;
  top: 50%;
  transform: translateY(-50%);
  border-right: 1px solid var(--Border-Light);
}
.bandwithInfo .usageBox:last-child::after {
  display: none;
}

.applicationUsageDetails {
  display: grid;
  grid-template-columns: 1fr repeat(4, auto);

  padding-bottom: 10px;
  overflow-x: auto;
  width: 100%;
}
.name {
  width: 100%;
}
.tableHeading {
  padding: 8px 16px;
  background: var(--Background-Light);
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  white-space: nowrap;
}
.bandwidth,
.lastHeading {
  text-align: right;
  padding-right: 0;
}
.details {
  padding: 10px 16px;
  white-space: nowrap;
  border-top: 1px solid var(--Border-Light);
}
.name,
.firstHeading {
  padding-left: 0;
}

@media only screen and (max-width: 767px) {
  .usageBox {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    gap: 0;
  }
  .infoBox {
    padding-top: 0;
    align-items: center;
    justify-content: center;
  }
  .item {
    text-align: center;
  }
  .bandwithInfo,
  .usageContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  .usageContainer .usageBox:last-child {
    grid-column: 1/-1;
  }
  .bandwithInfo .usageBox:first-child {
    grid-column: 1/-1;
    padding-top: 12px;
  }
  .bandwithInfo .usageBox:first-child::after {
    display: none;
  }
  .bandwithInfo {
    gap: 0;
  }
  /* .bandwithInfo .usageBox {
    border-bottom: 1px solid var(--Border-Light);
    background: none;
    border-radius: 10px 10px 0 0;
  }
  .bandwithInfo .usageBox:last-child {
    border: none;
  }
  .bandwithInfo .usageBox::after {
    display: none;
  } */
}
@media only screen and (max-width: 375px) {
  .usageContainer,
  .bandwithInfo {
    grid-template-columns: 1fr;
  }
  .bandwithInfo .usageBox {
    border-bottom: 1px solid var(--Border-Light);
    border-radius: 10px 10px 0 0;
  }
  .bandwithInfo .usageBox:last-child {
    border: none;
  }
}
