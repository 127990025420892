.wrapper {
  display: grid;
  grid-template-columns: auto 1fr;
  font-family: "Inter", sans-serif;

  width: 100%;
  height: 100%;
}
.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  width: 90%;
  margin: auto;
  padding-bottom: 24px;
}

.hamburger {
  font-size: 20px;
  color: var(--Primary-Color);
  position: absolute;
  top: 20px;
  left: 20px;
  display: none;
}
@media only screen and (max-width: 991px) {
  .wrapper {
    display: flex;
    flex-direction: column;
  }
  .hamburger {
    display: block;
    cursor: pointer;
  }
}
