.container {
  display: flex;
  justify-content: space-between;
  gap: 40px;
  padding: 64px 0;

  padding-bottom: 32px;
  margin-top: 100px;
}
.heading {
  line-height: 106%;
}
.infoContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: flex-start;
  max-width: 524px;
  width: 100%;
}
.highlight {
  color: var(--Text-Teritary);
}
.buttonContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  flex-wrap: wrap;
  padding-top: 20px;
}

.img {
  max-width: 444px;
  width: 100%;
}
@media only screen and (max-width: 1199px) {
  .container {
    display: flex;
    flex-direction: column;
    gap: 24px;
    justify-content: center;
    align-items: center;
    margin-top: 80px;
  }
  .infoContainer {
    max-width: 100%;
  }
}
@media only screen and (max-width: 767px) {
  .info {
    font-size: 20px;
  }
}
@media only screen and (max-width: 520px) {
  .infoContainer {
    gap: 16px;
  }
  .buttonContainer {
    padding-top: 16px;
  }
  .info {
    font-size: 18px;
  }
}
