.sidebar {
  display: flex;
  flex-direction: column;
  gap: 35px;
  max-width: 252px;
  width: 100%;
  padding: 18px 12px;

  position: fixed;
  left: 0px;
  top: 0px;
  background: var(--Bg-Light);
  border-right: 1px solid var(--Border-Light);
  z-index: 5;
  height: calc(100vh);
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 1001;
}
.logoContainer {
  width: 100%;
  margin: 0 20px;
}
.logo {
  max-width: 170px;
  width: 100%;
}

.navContainer {
  display: flex;
  flex-direction: column;
  gap: 40px;
  height: 100%;
}

.navItems,
.bottomNavs {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 16px 12px;
}

.navItem {
  color: var(--Text-Teritary);
  font-size: 14px;
  font-weight: 600;
  line-height: 142.87%;
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 8px;
}

.navActive {
  font-weight: 600;
  color: var(--Primary-Default);
}
.navIcon {
  max-width: 18px;
  width: 100%;
}

.bottomNavs {
  margin-top: auto;
}
.userContainer {
  display: flex;
  align-items: center;
  gap: 12px;
}
.avater {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  border-radius: var(--radius);
  border: 1px solid var(--Primitive);

  background: var(--Primitive);
}
.userImg {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  border-radius: var(--radius);
  border: 1px solid var(--Primitive);

  background: var(--Primitive);
  object-fit: cover;
  object-position: center;
}
.nameContainer {
  display: flex;
  flex-direction: column;
}
.notificationIcon {
  max-width: 18px;
  margin-left: auto;

  width: 100%;
  cursor: pointer;
}
/* ===== Scrollbar CSS ===== */
/* Firefox */

.sidebar {
  scrollbar-width: thin;
  scrollbar-color: var(--Primary-Default) var(--Bg-Light);
}
/* For Firefox */
/* For Firefox */

/* Chrome, Edge, and Safari */
.sidebar::-webkit-scrollbar {
  width: 3px;
}

.sidebar::-webkit-scrollbar-track {
  background: var(--Bg-Light);
}

.sidebar::-webkit-scrollbar-thumb {
  background: var(--Primary-Default);
  border-radius: 100%;
  border: none;
}
@media only screen and (max-width: 991px) {
  .sidebar {
    transform: translateX(-150%);
    transition: 0.3s;
    left: 0;
  }
  .overlay {
    position: fixed;

    background: rgba(0, 0, 0, 0.4);
    filter: blur(5px);
    height: 100vh;
    width: 100%;
    inset: 0;
    z-index: 1;
    cursor: pointer;
  }
  .showSidebar {
    transform: translateX(0%);

    transition: 0.3s;
  }
  .navContainer {
    gap: 10px;
  }
}
