.wrapper {
  display: flex;
  flex-direction: column;
  gap: 32px;
  width: 100%;
}
.header {
  display: flex;
  flex-direction: column;
  gap: 5px;
  max-width: 512px;
  width: 100%;
}

.billSummaryContainer {
  width: 100%;
}
.billSummaryHeader {
  padding-bottom: 20px;

  display: flex;
  flex-direction: column;
  gap: 4px;
}
.billSummaryHeading {
  display: flex;
  align-items: center;
  gap: 5px;
  flex-wrap: wrap;
}
.allBills {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.billSummary {
  display: grid;
  grid-template-columns: 1.7fr 1.3fr 1fr 1fr;
  align-items: center;
  width: 100%;
  border: 1px solid var(--Border-Light);
  border-radius: var(--radius);
  padding: 5px 0;
  width: 100%;
  overflow-x: auto;
  cursor: pointer;
}
.item {
  padding: 8px 20px;
  white-space: nowrap;
  border-bottom: 1px solid var(--Border-Light);
  height: 100%;
}
.border .item,
.border .statusContainer {
  border-bottom: none;
}
.statusContainer {
  border-bottom: 1px solid var(--Border-Light);
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.serverName,
.date {
}
.statusContainer {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 20px;
}
.status {
  border-radius: 4px;
  border: 1px solid #e2faf0;
  background: #f2fdf9;
  padding: 2px;
  font-size: 12px;
}
.amount {
  text-align: right;
}
.link {
  color: var(--Primary-Default);
  font-size: 14px;

  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 142.857%;
}
.completed {
  color: var(--Success);
}
.pending {
  color: var(--Warning);
}
.arrow {
  color: var(--Text-Placeholder);
  font-size: 16px;
  cursor: pointer;
}
@media only screen and (max-width: 767px) {
}
@media only screen and (max-width: 520px) {
}
