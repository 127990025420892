.footer {
  margin-top: auto;
  padding-top: 24px;
}

.wrapper {
  display: grid;
  grid-template-columns: 1fr repeat(2, 1fr) auto;
  gap: 24px 16px;
  position: relative;
  padding-bottom: 30px;
}

.itemContainer {
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: flex-start;
}
.heading {
  padding-bottom: 6px;
}
.item {
  color: var(--Text-Teritary);

  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 162.5%;
  transition: 0.3s;
}
.item:hover {
  transform: translateY(-2px);
}
.button {
  padding-left: 0;
  padding-right: 0;
  font-size: 14px;
}
.contactLink {
  display: flex;
  gap: 8px;
  color: #fff;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
}
.logo {
  width: 40px;
}

.copyRightContainer {
  border-top: 1px solid var(--Border-Light);
  padding: 15px 0;
  padding-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 16px;
}

@media only screen and (max-width: 991px) {
}
@media only screen and (max-width: 767px) {
  .wrapper {
    grid-template-columns: repeat(2, 1fr) auto;
  }
  .heading {
    font-size: 16px;
  }
  .item {
    font-size: 14px;
  }
}
@media only screen and (max-width: 640px) {
  .copyRightContainer {
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    padding-top: 24px;
  }
}
@media only screen and (max-width: 520px) {
  .wrapper {
    grid-template-columns: 1fr auto;
    max-width: 400px;
    width: 95%;
    margin: 0 auto;
  }
}
