.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2px;
  gap: 15px;
  width: 100%;
  padding-bottom: 32px;
  border-bottom: 1px solid var(--Border-Light);
}

.header {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.link {
  color: var(--Primary-Default);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 142.857%;
  margin-left: auto;
}
