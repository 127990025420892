.modal {
  align-items: start;
  min-height: auto;
  max-width: 730px;
}

.header {
  display: flex;

  align-items: center;
  gap: 12px;
  width: 100%;
  padding: 14px 20px;
  border-bottom: 1px solid var(--Border-Light);
}

.closeIcon {
  font-size: 18px;
  color: var(--Text-Placeholder);
  cursor: pointer;
  margin-left: auto;
}
.container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  gap: 40px;
  position: relative;
  padding: 32px;
}
.inputWrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-right: 32px;
  border-right: 1px solid var(--Border-Light);
  height: 100%;
}
.message {
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
  border: 1px solid var(--Border-Light);
  padding: 12px 16px;
  border-radius: var(--radius);
  background: var(--Bg-White);
  color: var(--Text-Primary);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 142.857%;
  text-overflow: ellipsis;
  width: 100%;
}
.inputFile {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  gap: 24px;
  height: 100%;
  width: 100%;
}

.fileInput {
  display: none;
}

.fileLabel {
  display: inline-block;
  padding: 10px 20px;
  background-color: var(--Bg-White);
  border: 2px dashed var(--Border-Light);
  border-radius: 5px;
  cursor: pointer;
  color: var(--Text-Placeholder);
  font-size: 14px;
  padding: 16px;
  width: 100%;
  text-align: center;
  width: 100%;
}

.fileList {
  margin-top: 10px;
  width: 100%;
}

.fileItem {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 5px 10px;
  color: var(--Text-Primary);
  width: 100%;
}

.checkIcon {
  color: var(--Success);
}

.removeIcon {
  color: var(--Error);
  cursor: pointer;
  margin-left: auto;
}
.buttonContainer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
  border-top: 1px solid var(--Border-Light);
  padding: 15px;
  flex-wrap: wrap;
}
.button {
  border: 1px solid var(--Border-Light);
  color: var(--Text-Teritary);
}

@media only screen and (max-width: 640px) {
  .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 24px;
    gap: 24px;
  }
  .inputWrapper {
    padding: 0;
    border: none;
    padding-bottom: 32px;
    border-bottom: 1px solid var(--Border-Light);
  }
}
