.tabs {
  display: flex;
  align-items: center;
  justify-content: start;

  border-bottom: 1px solid var(--Border-Light);
  width: 100%;

  background: var(--Base-White);
  border-radius: var(--radius-xl) var(--radius-xl) 0 0;
}

.tab {
  color: var(--Text-Teritary);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 142.857%;
  border-radius: 36px;
  padding: 15px 16px;
  padding-bottom: 8px;
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100%;
}

.activeTab {
  color: var(--Text-Primary);
  background: transparent;
  position: relative;
}

.activeTab::before {
  content: "";
  position: absolute;
  bottom: 0;
  transform: translateY(50%);
  border-radius: 3px 3px 0 0;
  height: 3px;
  width: 100%;
  background: var(--Primary-Default);
}
.activeTab:hover {
  transform: translateY(0);
}
.tab:hover {
  transform: translateY(0);
}
@media only screen and (max-width: 520px) {
  .tab {
    padding: 15px 12px;
  }
}
