.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;

  width: 100%;
  margin: auto;
}
.img {
  max-width: 76px;
  width: 100%;
}
.header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
}
.allApiKey {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
}
.apiKey {
  border-radius: var(--radius);
  border: 1px solid var(--Border-Light);
  background: var(--Bg-White);
  padding: 14px;
  display: flex;
  align-items: center;
  gap: 20px;
  width: 100%;
}
.key {
  word-break: break-all;
}
.buttonContainer {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-left: auto;
}
.copyIcon,
.deleteIcon {
  cursor: pointer;
  font-size: 18px;
  color: var(--Text-Placeholder);
  cursor: pointer;
  transition: 0.3s;
}
.copyIcon:hover,
.deleteIcon:hover {
  transform: translateY(-3px);
}
