.wrapper {
  max-width: 280px;
  margin: auto;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.infoContainer {
  display: flex;
  flex-direction: column;

  gap: 16px;
  border-radius: 8px;
  border: 1px solid var(--Border-Light);
  background: var(--Bg-Light);
  padding: 16px;
}
.button {
  background: var(--Error);
  margin-left: auto;
}
