.wrapper {
  border-right: 1px solid var(--Border-Light);
  padding-right: 20px;

  background: var(--Bg-White);
  max-width: 220px;
  width: 100%;
}
.header {
  display: none;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
}
.icon {
  font-size: 14px;
  color: var(--Heading-Color);
  cursor: pointer;
}
.close {
  position: fixed;
  top: 20px;
  right: 15px;
  color: var(--Text-Secondary);
  font-size: 20px;
  display: none;
}
.navContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.navItems {
  display: flex;
  flex-direction: column;
}
.border {
  border-top: 1px solid var(--Border-Light);
  border-bottom: 1px solid var(--Border-Light);
  padding: 10px 0;
}

.navItem {
  color: var(--Text-Teritary);
  font-size: 14px;
  font-weight: 600;
  line-height: 142.87%;
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 10px 8px;
  white-space: nowrap;
}

.navActive {
  font-weight: 600;
  color: var(--Primary-Default);
  background: var(--Bg-Light);
  border-radius: var(--radius);
}
.navIcon {
  max-width: 18px;
  width: 100%;
}
@media only screen and (max-width: 1199px) {
  .wrapper {
    border: none;
    border-bottom: 1px solid var(--Border-Light);
    padding: 0;
    padding-bottom: 10px;
    max-width: 100%;
  }
  .navContainer {
    transform: translateX(100%);
    transition: 0.3s;
    right: 0;
    position: fixed;
    top: 0;
    background: var(--Bg-Light);
    min-height: 100vh;
    padding: 24px;
    padding-top: 40px;
    z-index: 1000;
  }
  .close {
    display: block;
  }
  .header {
    display: flex;
  }
  .overlay {
    position: fixed;
    background: rgba(0, 0, 0, 0.4);
    filter: blur(5px);
    height: 100vh;
    width: 100%;
    inset: 0;
    z-index: 1;
    cursor: pointer;
  }
  .navContainer.show {
    transform: translateX(0%);
    right: 0;
    transition: 0.3s;
    z-index: 100;
  }
}
