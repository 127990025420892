.wrapper {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.header {
  display: flex;
  flex-direction: column;
  gap: 5px;
  max-width: 512px;
  width: 100%;
}

.heading {
  padding: 12px 0;
  padding-top: 0;
}

.spaceBetween {
  display: grid;
  grid-template-columns: 0.5fr 1fr;
  gap: 15px;
  align-items: center;
  padding: 12px 0;
  border-top: 1px solid var(--Border-Light);
  transition: 0.3s;
}
.spaceBetween:hover {
  background: var(--Bg-Light);
}
.value {
  margin-left: auto;
  text-align: right;
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: 500;
}
.copyIcon {
  cursor: pointer;
  transition: 0.3s;
}
.copyIcon:hover {
  transform: translateY(-3px);
}
.status {
  padding: 2px 6px;
  border-radius: var(--radius-sm);
  background: var(--Bg-Light);
  text-transform: capitalize;
}
.off {
  color: var(--Error);
  background: #fdf4f4;
  border: 1px solid #fce3e3;
}
.on {
  color: var(--Success);
  background: #e2faf0;
  border: 1px solid #f2fdf9;
}
.restarting {
  color: var(--Warning);
  background: #fff4de;
  border: 1px solid #fff4de;
}
