.features {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;

  width: 100%;
}

.feature {
  padding: 20px;
  border-radius: 12px;
  border: 1px solid var(--Border-Light);
  background: var(--Bg-White);
  display: flex;
  flex-direction: column;
  gap: 8px;
}
@media only screen and (max-width: 991px) {
  .features {
    grid-template-columns: 1fr 1fr;
    max-width: 640px;
    width: 100%;
    margin: 0 auto;
    gap: 8px;
  }
  .feature {
    padding: 16px;
  }
}
@media only screen and (max-width: 640px) {
  .features {
    grid-template-columns: 1fr;
    max-width: 350px;
    width: 100%;
  }
}
