.wrapper {
  display: flex;
  flex-direction: column;
  gap: 32px;
  height: 100%;
  width: 100%;
}
.balanceContainer {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 8px;
}
.dueAndUsage {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.box {
  border-radius: var(--radius-xl);
  border: 1px solid var(--Border-Light);
  background: var(--Base-White);
  padding: 16px;
  display: flex;
  flex-direction: column;

  gap: 16px;
  height: 100%;
}
.heading {
  display: flex;
  flex-direction: column;

  justify-content: center;
  gap: 4px;
}
.buttonContainer {
  padding-top: 16px;
  border-top: 1px solid var(--Border-Light);
  margin-top: auto;
}
.button {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  padding: 0;
}
.billSummaryContainer {
  padding: 20px;
  border-radius: var(--radius-xl);
  border: 1px solid var(--Border-Light);
}
.billSummaryHeader {
  padding-bottom: 20px;

  display: flex;
  flex-direction: column;
  gap: 4px;
}
.billSummaryHeading {
  display: flex;
  align-items: center;
  gap: 5px;
  flex-wrap: wrap;
}
.billSummary {
  display: grid;
  grid-template-columns: 1fr auto auto auto;
  width: 100%;
  border-top: 1px solid var(--Border-Light);
  overflow-x: auto;
}
.item {
  padding: 12px 20px;
  white-space: nowrap;
}
.serverName {
  padding-left: 0;
}
.amount {
  padding-right: 0;
  text-align: right;
}
.link {
  color: var(--Primary-Default);
  font-size: 14px;

  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 142.857%;
}
@media only screen and (max-width: 767px) {
  .balanceContainer {
    grid-template-columns: 1fr 1fr;
  }
  .box:last-child {
    grid-column: 1/-1;
  }
}
@media only screen and (max-width: 520px) {
  .balanceContainer {
    grid-template-columns: 1fr;
  }
}
