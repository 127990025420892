.wrapper {
  max-width: 708px;
  width: 90%;
  margin: 0 auto;
  margin: 120px auto;
  margin-bottom: 50px;
  display: flex;
  flex-direction: column;
  gap: 48px;
}
.heading {
  max-width: 400px;
}
.container {
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 48px;
}
.inputWrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.inputContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 8px;
}
.infoWrapper {
  display: flex;
  flex-direction: column;
  gap: 48px;
}
.infoContainer {
  background: linear-gradient(
    90deg,
    rgba(253, 234, 226, 0.7) 0%,
    rgba(226, 235, 251, 0.7) 100%
  );
  border-radius: 16px;
  gap: 80px;
  border: 1px solid #fdeae2;
  break-inside: avoid;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  max-width: 280px;
  width: 100%;
}
.message {
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
  border: 1px solid var(--Border-Light);
  padding: 12px 16px;
  border-radius: var(--radius);
  background: var(--Bg-White);
  color: var(--Text-Primary);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 142.857%;
  text-overflow: ellipsis;
  width: 100%;
}
.button {
  margin-top: auto;
}
@media only screen and (max-width: 1199px) {
  .wrapper {
    margin: 100px auto;
    margin-bottom: 50px;
  }
}
@media only screen and (max-width: 991px) {
  .wrapper {
    max-width: 380px;
    margin-left: auto;
    margin-right: auto;
    gap: 36px;
  }
  .container {
    display: flex;
    flex-direction: column;
  }
  .infoContainer {
    max-width: 100%;
    padding: 15px;
  }
}
@media only screen and (max-width: 520px) {
  .inputContainer {
    grid-template-columns: 1fr;
  }
}
