.wrapper {
  display: grid;
  grid-template-columns: auto 1fr;
  padding: 100px 0;
}

.headingContainer {
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: flex-start;
  padding-right: 40px;
  border-right: 1px solid var(--Border-Light);
  max-width: 280px;
}
.infoContainer {
  padding-left: 40px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 15px;
}
.info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--Border-Light);
  padding: 20px;
  border-radius: var(--radius-xl);
}
.number {
  display: flex;
  align-items: center;
}
.plusIcon {
  font-size: 32px;
}
@media only screen and (max-width: 1199px) {
  .wrapper {
    display: flex;
    flex-direction: column;
    gap: 32px;
  }
  .headingContainer {
    padding: 0;
    border: none;
    max-width: 100%;
    align-items: center;
  }
  .infoContainer {
    padding: 0;
  }
}
@media only screen and (max-width: 767px) {
  .wrapper {
    padding: 50px 0;
  }
  .infoContainer {
    grid-template-columns: 1fr 1fr;
    gap: 16px;
  }
  .info:last-child {
    grid-column: 1/-1;
  }
}

@media only screen and (max-width: 520px) {
  .title {
    text-align: center;
    font-size: 20px;
  }
  .infoContainer {
    grid-template-columns: 1fr;
  }
}
