.allInputs {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
}
.inputWrapper,
.dropDownContainer {
  display: grid;
  grid-template-columns: 1fr 1.5fr;
  gap: 15px;
  align-items: center;
  width: 100%;
}
.input {
  padding: 0;
  border: none;
  padding: 10px 0;
  background: transparent;
}

.inputContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  width: 100%;
}

.editIcon {
  font-size: 24px;
  color: var(--Text-Placeholder);
  cursor: pointer;
}
.buttonContainer {
  display: flex;
  justify-content: flex-start;
}
@media only screen and (max-width: 767px) {
  .inputWrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
  }
  .allInputs {
    gap: 18px;
  }
}
