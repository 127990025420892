.tooltip-box {
  background: var(--Bg-White);

  border: var(--Border-Light);
  border-radius: var(--radius);
  min-width: 80px;
}
.tooltip-title {
  font-family: "Inter", sans-serif;
  font-size: 12px;
  background: var(--Bg-Light);
  font-weight: 500;
  color: var(--Text-Secondary);
  margin-bottom: 5px;
  border-bottom: 1px solid var(--Border-Light);
  padding: 6px;
}
.tooltip-percentage,
.tooltip-ghz,
.tooltip-date {
  font-family: "Inter", sans-serif;
  font-size: 12px;
  color: var(--Text-teritary);
  font-weight: 500;
  padding: 0 6px;
  padding-bottom: 3px;
}
.tooltip-ghz {
  padding-bottom: 6px;
}
.custom-xaxis-tooltip {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 50%;
  transform: translate(-50%, -50%);
  max-width: max-content;
  background: #fff;
}
.tooltip {
  background: #fff;
} /* Chart.module.css */
.apexcharts-xaxistooltip {
  background: var(--Base-White) !important;
  border: 1px solid #ccc;
  padding: 8px;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  color: #333;
  font-family: Inter, sans-serif;
  font-size: 12px;
  max-width: 100px !important;
  text-align: center !important;
  opacity: 0;
  display: none;
}

.custom-xaxis-tooltip .date {
  color: #ff6347; /* Custom color for the date */
  font-weight: bold;
}
