.wrapper {
  display: flex;
  flex-direction: column;
  gap: 64px;
  padding: 100px 0;
}

.header {
  max-width: 718px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}
.stepsContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 20px;
  border-right: 1px solid var(--Border-Light);
}
.stepsContainer:last-child {
  border: none;
}

.id {
  background: linear-gradient(90deg, #f16d37 0%, #f8b396 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.steps {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.step {
  display: flex;
  align-items: start;
  gap: 8px;
}
.checkmarkIcon {
  max-width: 12px;
  margin-top: 6px;
}

@media only screen and (max-width: 1199px) {
}
@media only screen and (max-width: 991px) {
  .container {
    grid-template-columns: 1fr 1fr;
    max-width: 640px;
    width: 100%;
    margin: 0 auto;
  }
  .stepsContainer:nth-child(2) {
    border-right: none;
  }
}
@media only screen and (max-width: 767px) {
  .wrapper {
    gap: 32px;
    padding: 50px 0;
  }
  .container {
    grid-template-columns: 1fr;
  }
  .stepsContainer {
    border: none;
    border-bottom: 1px solid var(--Border-Light);
    padding: 20px 0;
  }
  .stepsContainer:last-child {
    border-bottom: none;
  }
  .title {
    font-size: 18px;
  }
  .info {
    font-size: 14px;
  }
  .tagline {
    font-size: 18px;
  }
}

@media only screen and (max-width: 640px) {
}
@media only screen and (max-width: 520px) {
  .tagline {
    font-size: 16px;
  }
}
