.modal {
  align-items: start;
  min-height: auto;
  max-width: 580px;
}

.header {
  display: flex;

  align-items: center;
  gap: 12px;
  width: 100%;
  padding: 14px 20px;
  border-bottom: 1px solid var(--Border-Light);
}

.closeIcon {
  font-size: 18px;
  color: var(--Text-Placeholder);
  cursor: pointer;
  margin-left: auto;
}
.container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 40px;
  position: relative;
  padding: 40px;
}
.infoContainer {
  padding-right: 40px;
  border-right: 1px solid var(--Border-Light);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
}
.qrCodeContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.qrCode {
  max-width: 180px;

  width: 100%;
  display: block;
  padding: 4px;
  border-radius: 12px;
  border: 1px solid var(--Border-Light);
}
.buttonContainer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
  border-top: 1px solid var(--Border-Light);
  padding: 15px;
  flex-wrap: wrap;
}
.button {
  border: 1px solid var(--Border-Light);
  color: var(--Text-Teritary);
}

@media only screen and (max-width: 640px) {
  .container {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    padding: 24px;
    gap: 24px;
  }
  .infoContainer {
    padding: 0;

    border: none;
    align-items: center;
    gap: 12px;
  }
  .qrCodeContainer {
    padding-bottom: 24px;
    border-bottom: 1px solid var(--Border-Light);
    width: 100%;
  }
  .heading {
    text-align: center;
  }
  .info {
    text-align: center;
  }
}
