.container {
  display: flex;
  flex-direction: column;
  max-height: 500px;
  height: 100%;
  justify-content: space-between;
  width: 100%;
}

.messages {
  flex: 1;

  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 10px;
  padding-bottom: 12px;
}

.message {
  padding: 10px;
  border-radius: 10px;
  max-width: 80%;
  word-break: break-all;
}

.userMessage {
  border-radius: 8px;
  background: var(--Bg-Light);
  margin-left: auto;
}

.supportMessage {
  display: flex;
  flex-direction: column;
  gap: 12px;

  border-radius: 8px;
  background: var(--Bg-White);
  border: 1px solid var(--Border-Light);
}

.inputContainer {
  display: flex;
  padding: 6px;
  border: 1px solid var(--Border-Light);
  background-color: var(--Bg-White);
  border-radius: 8px;
  flex-shrink: 0;
}

.input {
  flex: 1;
  padding: 10px;
  border-radius: 5px;
  margin-right: 10px;
  color: var(--Text-Secondary);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 142.857%;
  resize: none; /* Prevent manual resizing */
  overflow: hidden;
  border: none;
  outline: none;
  width: 100%;
}

.button {
  margin-left: auto;
  padding: 8px 12px;
  font-size: 14px;
}
@media only screen and (max-width: 380px) {
  .ticketInfoContainer {
    padding: 12px;
  }
}
