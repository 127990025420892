.wrapper {
  padding: 32px 30px;
  max-width: calc(1199px + 252px);
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;

  gap: 32px;
  padding-left: calc(252px + 30px);
  overflow-x: hidden;
  min-height: 100vh;
}
.headerWrapper {
  display: flex;

  justify-content: space-between;
  gap: 16px;
}
.iconContainer {
  cursor: pointer;
  transition: 0.3s;
  display: none;
  margin-top: 3px;
}
.iconContainer:hover {
  transform: translateY(-3px);
}
.openSidebar {
  font-size: 30px;
  color: var(--Text-Primary);
}

@media only screen and (max-width: 991px) {
  .wrapper {
    padding: 24px 0;
    width: 90%;
    margin: 0 auto;
  }
  .iconContainer {
    display: block;
    position: fixed;
    left: 0;
    top: 75px;
    background: var(--Heading-Color);
    border-radius: var(--radius);

    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  .openSidebar {
    color: var(--Base-White);
    font-size: 24px;
  }
}
