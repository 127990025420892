.container {
  display: grid;
  grid-template-columns: 1fr 1.9fr;
  gap: 20px;
}
.wrapper {
  height: 100%;
  border: 1px solid var(--Border-Light);
  border-radius: var(--radius-xl);
}
@media only screen and (max-width: 1280px) {
  .container {
    grid-template-columns: 1fr;
    width: 100%;
  }
}
