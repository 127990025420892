.wrapper {
  display: flex;
  flex-direction: column;
  gap: 32px;
}
.header {
  display: flex;
  align-items: center;
  gap: 15px;
  flex-wrap: wrap;
}
.button {
  margin-left: auto;
  gap: 10px;
}

.titleAndInfo {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.numberOfServers {
  color: var(--Text-Primary);
  font-weight: 600;
}
.container {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
