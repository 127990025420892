.wrapper {
  display: flex;
  flex-direction: column;
  gap: 64px;
}

.header {
  max-width: 718px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.container {
  display: grid;
  grid-template-columns: 1fr 348px;
  gap: 32px;
  align-items: center;
}
.imgContainer {
  width: 100%;
  max-width: 740px;
  background: linear-gradient(
    90deg,
    rgba(253, 234, 226, 0.3) 0%,
    rgba(226, 235, 251, 0.3) 100%
  );
  border-radius: 16px;
  border: 1px solid #fdeae2;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 32px;
}
.img {
  width: 100%;
}
.steps {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.step {
  border-radius: 12px;
  border: 1px solid var(--Bg-White);
  background: var(--Bg-White);
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  cursor: pointer;
}
.id {
  background: linear-gradient(90deg, #f16d37 0%, #f8b396 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.activeStep {
  border: 1px solid #f16d37;
}
@media only screen and (max-width: 1199px) {
  .container {
    grid-template-columns: 1fr;
  }
  .imgContainer {
    margin: 0 auto;
  }
  .steps {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
  .step {
    padding: 16px;
  }
}
@media only screen and (max-width: 991px) {
  .steps {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    max-width: 640px;
    width: 100%;
    margin: 0 auto;
  }
  .imgContainer {
    padding: 24px;
  }
}
@media only screen and (max-width: 767px) {
  .wrapper {
    gap: 32px;
  }
  .title {
    font-size: 20px;
  }
  .info {
    font-size: 14px;
  }
  .tagline {
    font-size: 18px;
  }
}

@media only screen and (max-width: 640px) {
  .steps {
    grid-template-columns: 1fr;
    max-width: 350px;
    width: 100%;
  }
  .imgContainer {
    padding: 20px;
  }
}
@media only screen and (max-width: 520px) {
  .title {
    font-size: 18px;
  }
  .tagline {
    font-size: 16px;
  }
  .imgContainer {
    padding: 16px;
  }
}
