.heading {
  border-radius: var(--radius);
  border: 1px solid var(--Border-Light);
  background: var(--Bg-Light);
  padding: 4px 6px;
}
@media only screen and (max-width: 767px) {
  .heading {
    font-size: 14px;
  }
}
