.container {
  display: grid;
  grid-template-columns: 380px 1fr;
  gap: 32px;
}

.leftSide {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.infoContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 16px;
  border-radius: var(--radius-xl);
  border: 1px solid var(--Border-Light);
}
.availableForWithdraw {
  background: linear-gradient(
    90deg,
    rgba(253, 234, 226, 0.7) 0%,
    rgba(226, 235, 251, 0.7) 100%
  );
}
.button {
  margin-top: 12px;
}
.referLink {
  margin-top: 10px;
}
.linkContainer {
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 8px;
}
.link {
  border-radius: var(--radius);
  border: 1px solid var(--Border-Light);
  padding: 10px;
  word-break: break-all;
  font-size: 14px;
  color: var(--Text-Primary);
}
.copyContainer {
  cursor: pointer;
  transition: 0.3s;
  width: 40px;
  height: 40px;
  border: 1px solid var(--Info);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: var(--radius);
  background: var(--Info);
}
.copyContainer:hover {
  transform: translateY(-3px);
}
.copyIcon {
  color: var(--Base-White);
}

/* referel container */

.referListContainer {
  border-radius: var(--radius);
  border: 1px solid var(--Border-Light);
  display: flex;
  flex-direction: column;

  position: relative;

  min-height: 350px;
  overflow-y: auto;
  padding-bottom: 10px;
  padding-right: 3px;
}
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 15px;
  padding: 12px;
  padding-bottom: 15px;
}
.input {
  max-width: 200px;
  margin-left: auto;
}
.referList {
  display: grid;
  grid-template-columns: auto 1fr 1fr auto;
  overflow-x: auto;
}
.tableHeading {
  padding: 12px 15px;
  background: var(--Bg-Light);
  white-space: nowrap;
  position: sticky;
  top: 0px;
}
.item {
  padding: 12px 15px;
  white-space: nowrap;
  border-bottom: 1px solid var(--Border-Light);
}
.id,
.firstHeading {
}
.earnHeading,
.earn {
  text-align: right;
}
@media only screen and (max-width: 1199px) {
  .container {
    display: flex;
    flex-direction: column;
  }
  .leftSide {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  .referLink {
    margin-top: 0;
  }
}
@media only screen and (max-width: 767px) {
  .container {
    max-width: 575px;
    margin: 0 auto;
    width: 100%;
  }
  .leftSide {
    display: grid;
    grid-template-columns: 1fr;
    width: 100%;
  }
}
