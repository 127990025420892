.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 40px;

  padding-bottom: 32px;
}
.heading {
  line-height: 106%;
}
.infoContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: flex-start;
  max-width: 454px;
  width: 100%;
}

.buttonContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 8px;
  padding-top: 20px;
}
.serverAndApplicatons {
  background: linear-gradient(
    90deg,
    rgba(253, 234, 226, 0.3) 0%,
    rgba(226, 235, 251, 0.3) 100%
  );
  width: auto;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  border-radius: 16px;
  gap: 80px;
  border: 1px solid #fdeae2;
  padding: 32px;

  width: max-content;
  position: relative;
}
.serverAndApplicatons::before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 1px;
  height: 90%;
  background: linear-gradient(90deg, #fdeae2 0%, #e2ebfb 100%);
}
.server,
.application {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 12px 0;
  border-bottom: 1px solid #fdeae2;
}
.application {
  border-bottom: 1px solid #e2ebfb;
}
.server:last-child,
.application:last-child {
  border: none;
}
.title {
  padding-bottom: 8px;
}
.logoContainer {
  width: 48px;
  height: 48px;
  border-radius: var(--radius);
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--Bg-White);
}
.logo {
  max-width: 28px;
  width: 100%;
}

@media only screen and (max-width: 991px) {
  .container {
    display: flex;
    flex-direction: column;
    gap: 60px;
    justify-content: center;
    align-items: center;
  }
  .heading {
    font-size: 32px;
  }
  .infoContainer {
    max-width: 100%;
    margin-left: auto;
  }
}
@media only screen and (max-width: 767px) {
  .info {
    font-size: 14px;
  }
}
@media only screen and (max-width: 640px) {
  .serverAndApplicatons {
    grid-template-columns: 1fr;
    max-width: 400px;
    width: 100%;
    padding: 20px;
    gap: 30px;
  }
  .serverAndApplicatons::before {
    width: 90%;
    height: 1px;
  }
  .server,
  .application {
    padding: 8px 0;
  }
  .applications .title {
    padding-top: 23px;
  }
  .logoContainer {
    width: 40px;
    height: 40px;
  }
  .logo {
    max-width: 20px;
  }
}
@media only screen and (max-width: 520px) {
  .heading {
    font-size: 28px;
  }
  .infoContainer {
    gap: 16px;
  }
  .buttonContainer {
    padding-top: 16px;
  }
}
