.tabs {
  display: flex;
  align-items: center;

  background: var(--Bg-Light);
  border-radius: 8px;
  padding: 2px;
}

.tab {
  font-size: 14px;
  font-weight: 500;
  box-shadow: 0px 1px 2px 0px rgba(2, 6, 23, 0.04);

  background: var(--Background-White);
  border-radius: var(--radius);
  padding: 8px 8px;
  display: block;
  cursor: pointer;
  color: var(--Text-Teritary);
}
.selectedTab {
  background: var(--Bg-White);
  color: var(--Primary-Default);
  font-weight: 500;
}
