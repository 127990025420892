.infoContainer {
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
  justify-content: center;
  max-width: 280px;

  margin: auto;
}
.inputContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
}
.link {
  color: var(--Primary-Default);
  font-size: 14px;

  text-align: center;
  font-style: normal;
  font-weight: 600;
  line-height: 142.857%;
}
