.wrapper {
  display: flex;
  flex-direction: column;
  gap: 32px;
}
.header {
  display: flex;
  align-items: center;
  gap: 15px;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.button {
  margin-left: auto;
  gap: 10px;
}

.titleAndInfo {
  display: flex;
  flex-direction: column;
}

.numberOfApplications {
  color: var(--Text-Primary);
  font-weight: 600;
}
.infoWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
  max-width: 280px;
  width: 90%;
  margin: auto;
}
.img {
  max-width: 76px;
  width: 100%;
}
.container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  gap: 80px;
  max-width: 648px;
  margin: auto;
  width: 100%;
  height: 100%;
  position: relative;
}
.container::before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 1px;
  height: 100%;
  background: var(--Border-Light);
}

.infoContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
  width: 100%;
}
.stepsContainer {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.steps {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.step {
  display: flex;
  gap: 24px;
  position: relative;
  z-index: 1;
}
.step::before {
  content: "";
  position: absolute;
  left: 0;
  top: 15px;
  transform: translateX(13px);
  width: 3px;
  height: 100%;
  background: var(--Success-Default);
  z-index: -1;
}
.step:last-child::before {
  display: none;
}
.id {
  min-width: 30px;
  min-height: 30px;
  max-width: 30px;
  max-height: 30px;
  aspect-ratio: 1/1;
  border-radius: 50%;
  background: var(--Success-Default);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 600px;
  color: var(--Base-White);
}
.infoBox {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
@media only screen and (max-width: 991px) {
  .container {
    display: flex;
    flex-direction: column;
    gap: 50px;
  }
  .container::before {
    display: none;
  }
  .infoWrapper {
    padding-bottom: 50px;
    border-bottom: 1px solid var(--Border-Light);
    width: 100%;
  }
}
